/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
body {
  /* background-color: rgba(0, 0, 0, 0.8); */
  font-family: "Poppins";
  /* color: white; */
}
/* .microphone-wrapper {

} */
/*  
.mircophone-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 45.7vh;
}
.microphone-icon-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: linear-gradient(128deg, #ffffff, #647c88);
  padding: 20px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}
.microphone-icon-container.listening::before {
  content: "";
  width: 100px;
  height: 100px;
  background-color: #ffffff81;
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%) scale(1.4);
  border-radius: 50%;
  animation: listening infinite 1.5s;
}
@keyframes listening{
  0%{
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100%{
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.4);
  }
}
.microphone-icon {
  width: 100%;
  height: 100%;
}
.microphone-status {
  font-size: 22px;
  margin-right: 20px;
  min-width: 215px;
  color: black;
}
.btn {
  border: none;
  padding: 10px 30px;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 5px #ffffff1a;
}
.microphone-result-container {
  text-align: center;
  height: 45vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;

}
.microphone-result-text {
  margin-bottom: 30px;
  width: 70vw;
  overflow-y: auto;
  color: black;
}
.microphone-reset {
  border: 1px solid #fff;
  background: none;
  color: black;
  width: fit-content;
}

.openai-response-text {
  color: black;
} */
 /* src/components/Main/Main.css *//* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
body {
  /* background-color: rgba(0, 0, 0, 0.8); */
  font-family: "Poppins";
  /* color: white; */
}
/* .microphone-wrapper {

} */
/*  
.mircophone-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 45.7vh;
}
.microphone-icon-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: linear-gradient(128deg, #ffffff, #647c88);
  padding: 20px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}
.microphone-icon-container.listening::before {
  content: "";
  width: 100px;
  height: 100px;
  background-color: #ffffff81;
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%) scale(1.4);
  border-radius: 50%;
  animation: listening infinite 1.5s;
}
@keyframes listening{
  0%{
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100%{
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.4);
  }
}
.microphone-icon {
  width: 100%;
  height: 100%;
}
.microphone-status {
  font-size: 22px;
  margin-right: 20px;
  min-width: 215px;
  color: black;
}
.btn {
  border: none;
  padding: 10px 30px;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 5px #ffffff1a;
}
.microphone-result-container {
  text-align: center;
  height: 45vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;

}
.microphone-result-text {
  margin-bottom: 30px;
  width: 70vw;
  overflow-y: auto;
  color: black;
}
.microphone-reset {
  border: 1px solid #fff;
  background: none;
  color: black;
  width: fit-content;
}

.openai-response-text {
  color: black;
} */
 /* src/components/Main/Main.css */
 /* src/components/Main/Main.css */
 
 /* Main.css */
 .main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.dot {
  animation: pulse 2s infinite; /* Example animation */
}

.dot.speaking {
  background-color: rgb(0, 10, 87); /* Change color when speaking */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.speaking {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

/* Question box styling */
.question-box {
  width: 90%; 
  max-width: 800px;
  padding: 15px 20px;
  border-radius: 10px;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  z-index: 10;
}

/* Report card styling */
.report-card {
  width: 80%;
  max-width: 900px;
  padding: 30px;
  border-radius: 12px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.report-card h5 {
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.report-card p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.speaking {
  animation: bounce 1.2s infinite ease-in-out;
}

/* Typing indication */
@keyframes bounce {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}



