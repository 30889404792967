
 /* Main.css */
 .main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  
  .dot {
    animation: pulse 2s infinite; /* Example animation */
  }
  
  .dot.speaking {
    background-color: rgb(0, 10, 87); /* Change color when speaking */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  .speaking {
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  
  /* Question box styling */
  .question-box {
    width: 90%; 
    max-width: 800px;
    padding: 15px 20px;
    border-radius: 10px;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    z-index: 10;
  }
  
  /* Report card styling */
  .report-card {
    width: 80%;
    max-width: 900px;
    padding: 30px;
    border-radius: 12px;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
  }
  
  .report-card h5 {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
  
  .report-card p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .speaking {
    animation: bounce 1.2s infinite ease-in-out;
  }
  
  /* Typing indication */
  @keyframes bounce {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
  
  
  .mic-button {
    position: relative;
    overflow: hidden;
  }
  
  .mic-button.active::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0.5);
    animation: ripple 1.5s infinite ease-out;
  }
  
  @keyframes ripple {
    0% {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }
  

  
  @keyframes wave {
    0% {
      stroke-dashoffset: 5;
    }
    50% {
      stroke-dashoffset: 10;
    }
    100% {
      stroke-dashoffset: 5;
    }
  }
  
  .mic-button {
    transition: background-color 0.3s;
  }
  
  .circles {
    height: 90vmin;
    position: relative;
    width: 90vmin;
    
    > div {
      animation: growAndFade 3s infinite ease-out;
      background-color: #F05246;
      border-radius: 50%;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
    }
    
    .circle1 {
      animation-delay: 1s;    
    }
    .circle2 {
      animation-delay: 2s; 
    }
    .circle3 {
      animation-delay: 3s;
    }
  }
  
  @keyframes growAndFade {
    0% {
      opacity: .25;
      transform: scale(0);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  .gradient-bg{
    background: linear-gradient(135deg, #ffffff, #ffe1c9, #ffd2b0);
    /* animation: rotate 3s infinite ease-in-out; */

  }

  /* @keyframes rotate {
    0% {
      background: linear-gradient(135deg, #ffffff, #ffd2b0);
    }
    100% {
      background: linear-gradient(135deg,#ffd2b0, #ffffff);
    }
  } */