/* Container Styling */
.error-modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    perspective: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  /* 3D Card Animation */
  .error-3d-card {
    width: 350px;
    height: 250px;
    position: relative;
    transform-style: preserve-3d;
    animation: rotate3D 6s infinite ease-in-out;
    transition: transform 0.5s ease;
  }
  
  .error-3d-card:hover {
    animation: none;
    transform: rotateY(180deg);
  }
  
  /* Card Faces */
  .error-card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .error-front {
    background: linear-gradient(135deg, #ff6b6b, #ffcc00);
    color: #fff;
    text-align: center;
  }
  
  .error-back {
    background: linear-gradient(135deg, #2c3e50, #2980b9);
    color: #fff;
    text-align: center;
    transform: rotateY(180deg);
  }
  
  /* Decorative Icon */
  .error-icon {
    width: 80px;
    margin-bottom: 10px;
    filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.2));
  }
  
  /* Text Styling */
  .error-title {
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
  
  .error-message {
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .error-back-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .error-back-message {
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  /* Button Styling */
  .error-retry-button {
    padding: 12px 30px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 8px;
    background: #3498db;
    color: #fff;
    box-shadow: 0 5px 15px rgba(52, 152, 219, 0.4);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .error-retry-button:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(52, 152, 219, 0.6);
  }
  
  /* Support Text */
  .error-support {
    font-size: 0.875rem;
    color: #7f8c8d;
  }
  
  .error-support a {
    color: #2980b9;
    font-weight: bold;
    text-decoration: none;
  }
  
  .error-support a:hover {
    text-decoration: underline;
  }
  
  /* Keyframe Animation */
  @keyframes rotate3D {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  