.fancy-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .fancy-loader div {
    width: 20px;
    height: 20px;
    background: #ff2600;
    border-radius: 50%;
    animation: bounce 1s infinite;
  }
  
  .fancy-loader div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .fancy-loader div:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .fancy-loader div:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
      opacity: 0.5;
    }
    50% {
      transform: translateY(-15px);
      opacity: 1;
    }
  }

  .navbar {
    position: sticky; /* Or fixed */
    top: 0;
    z-index: 1000; /* High enough to appear above other elements */
  }
  